
import firebase from 'firebase';
import _ from 'lodash';
import { observable, makeObservable, computed, runInAction, action, makeAutoObservable } from 'mobx';
import moment from 'moment';
import UserModel from '../common/models/UserModel';
import { isMobile } from '../common/utils/Utils';
import UserInfoProvider from '../services/UserInfoProvider';
import RootStore from './RootStore';
import SettingsStore from './SettingsStore';

const hideNavigationSiderPages = ['/live/', '/meeting/']

class ViewStateStore {
    rootStore: RootStore;
    settingsStore: SettingsStore;
    isMobile: boolean;
    siderCollapsed = true;
    fullPageMode = false;
    userInfoProvider: UserInfoProvider;
    primaryColor = '#1cbcbe';
    primaryColorContrastLight = '#ecfcfa';
    primaryColorContrastDark = '#0a6a6c';
    premiumColor = '#caa83c'
    paywallModalVisible = false;
    checkoutCompleted = false;
    eventTerm: string = 'event';

    constructor(rootStore: RootStore, settingsStore: SettingsStore) {
        makeObservable(this, {
            isMobile: observable,
            siderCollapsed: observable,
            fullPageMode: observable,
            primaryColor: observable,
            primaryColorContrastLight: observable,
            paywallModalVisible: observable,
            checkoutCompleted: observable,
            eventTerm: observable,
            setFullPageMode: action,
            setSiderCollapsed: action,
            setPaymentWallVisible: action,
            setCheckoutCompleted: action,
        })

        this.isMobile = isMobile();
        this.rootStore = rootStore;
        this.settingsStore = settingsStore;
        this.userInfoProvider = new UserInfoProvider();

        this.rootStore.sessionStore.onAuth((auth) => {
            if (auth) {
                this.setOrUpdateUserProps(auth);
            }
        });

        this.settingsStore.onSettingsChanged((settings) => {
            if(!settings) {
                return;
            }

            const { space, terminology } = settings;
            this.eventTerm = terminology.eventTerm || 'event';
            const primary = space?.primaryColor || 'black';
            const light = space?.primaryLightColor || 'black';
            const dark = space?.primaryDarkColor || 'black';

            document.body.style.setProperty('--primary', primary);
            this.primaryColor = primary;
            document.body.style.setProperty('--primary-contrast-light', light);
            this.primaryColorContrastLight = light;
            document.body.style.setProperty('--primary-contrast-dark', dark);
            this.primaryColorContrastDark = dark;
        })
    }

    setSiderCollapsed = (collapsed: boolean, userInitiated = false) => {
        this.siderCollapsed = collapsed;
        if (this.rootStore.sessionStore.isAuthed && userInitiated && !this.isMobile) {
            this.rootStore.sessionStore.updateUser({ settings: { siderCollpased: collapsed } });
        }

    }

    setOrUpdateUserProps = async (auth: UserModel) => {
        if (auth.settings?.siderCollpased !== undefined && !this.isMobile && !this.isLiveEventPage()) {
            this.siderCollapsed = auth.settings.siderCollpased;
        }

        await this.rootStore.sessionStore.updateUser({ timezone: moment.tz.guess() })
        await this.updateUserLocationIfNeeded(auth);
    }

    setFullPageMode = (value: boolean) => {
        this.fullPageMode = value;
    }

    hideNavigationSider = () => {
        const hideSider = hideNavigationSiderPages.find(page => window.location.pathname.indexOf(page) > -1)
        return hideSider;
    }

    setPaymentWallVisible = (visible: boolean, source?: string, activeMembership: boolean = false) => {
        this.paywallModalVisible = visible;

        if (visible) {
            if(!activeMembership) {
                this.rootStore.sessionStore.addActivityItem(`Payment Wall Shown (Source: ${source})`, 'payment', 'money');    
                const { authUser } = this.rootStore.sessionStore;
                this.rootStore.firebase.notify(`🚧 *${authUser?.fullName}* from ${authUser?.company?.name} hit payment wall (Source: ${source})`);
            }
        }
    }

    setCheckoutCompleted = () => {
        this.checkoutCompleted = true;
    }

    private isLiveEventPage = () => {
        return _.includes(window.location.href, 'live')
    }

    private updateUserLocationIfNeeded = async (auth: UserModel) => {
        if (!auth.location || moment.unix(auth.location.lastUpdate.seconds).add(7, 'days') < moment()) {
            const info = await this.userInfoProvider.getInfo();
            await this.rootStore.sessionStore.updateUser({
                location: {
                    city: info.city,
                    country: info.country,
                    isEU: info.isEU,
                    lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
                    countryCode: info.countryCode,
                    continent: info.continent,
                    latitude: info.latitude,
                    longitude: info.longitude,
                    region: info.region,
                    flagIcon: info.flagIcon,
                }
            })

        }
    }
}


export default ViewStateStore;


