import _ from "lodash";
import moment from "moment";
import { MeetingParticipant } from "./MeetingModel";

class UserModel {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    photoUrl?: string;
    role?: string;
    bio?: string;
    leadersMember?: boolean;
    primarySectors?: string[];
    secondarySectors?: string[];
    onboardingCompleted?: boolean;
    objectives?: ObjectiveModel[];
    company?: CompanyInfoModel;
    suggestedMembers?: UserModel[];
    kapara?: boolean;
    manager?: boolean;
    guest?: boolean;
    guestInvitationId?: string;
    settings?: Settings;
    location?: Location;
    badges?: Badges;
    type?: string;
    presence?: Presence;
    timezone?: string;
    test?: boolean;
    staff?: boolean;
    explainersShown?: Explainers;
    gender?: Gender;
    followers?: string[];
    following?: string[];
    referrerUser?: string;
    accomplishments?: Accomplishments;
    stats?: UserStats;
    membership?: Membership;
    phoneNumber?: string;
    createdAt?: any;

    constructor(id?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        photoUrl?: string,
        role?: string,
        bio?: string,
        leadersMember?: boolean,
        onboardingCompleted?: boolean,
        primarySectors?: string[],
        secondarySectors?: string[],
        company?: CompanyInfoModel,
        objectives?: ObjectiveModel[],
        suggestedMembers?: UserModel[],
        kapara?: boolean,
        manager?: boolean,
        settings?: Settings,
        location?: Location,
        badges?: Badges,
        type?: string,
        presence?: Presence,
        timezone?: string,
        guest?: boolean,
        guestInvitationId?: string,
        explainersShown?: Explainers,
        staff?: boolean,
        test?: boolean,
        gender?: Gender,
        followers?: string[],
        following?: string[],
        referrerUser?: string,
        accomplishments?: Accomplishments,
        createdAt?: any,
        stats?: UserStats,
        membership?: Membership,
        phoneNumber?: string) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.photoUrl = photoUrl;
        this.role = role;
        this.bio = bio;
        this.leadersMember = leadersMember;
        this.onboardingCompleted = onboardingCompleted;
        this.primarySectors = primarySectors;
        this.secondarySectors = secondarySectors;
        this.company = company;
        this.objectives = objectives;
        this.suggestedMembers = suggestedMembers?.map(m => UserModel.mapFromServer(m));
        this.kapara = kapara;
        this.manager = manager;
        this.settings = settings;
        this.location = location;
        this.badges = badges;
        this.type = type;
        this.presence = presence;
        this.timezone = timezone;
        this.guest = guest;
        this.guestInvitationId = guestInvitationId;
        this.explainersShown = explainersShown;
        this.staff = staff;
        this.test = test;
        this.gender = gender;
        this.followers = followers;
        this.following = following;
        this.referrerUser = referrerUser;
        this.accomplishments = accomplishments;
        this.createdAt = createdAt;
        this.stats = stats;
        this.membership = membership;
        this.phoneNumber = phoneNumber;
    }

    get fullName() {
        return this.firstName + ' ' + this.lastName;
    }

    get shortName() {
        return UserModel.shortName(this.firstName, this.lastName);
    }

    get title() {
        return UserModel.title(this.role, this.company)
    }

    get interests() {
        return this.primarySectors?.concat(this.secondarySectors || []);
    }

    get isManager() {
        return this.kapara || this.manager;
    }

    get isAdmin() {
        return this.kapara;
    }

    get isGuest() {
        return this.guest;
    }

    get isStaff() {
        return this.staff || this.isManager;
    }

    get isOnline() {
        if(!this.presence || !this.presence.lastChanged) {
            return false;
        }
        
        const seenRecently = (moment().unix() - this.presence?.lastChanged.seconds) <= 600 // 10 minutes; 
        return this.presence?.state === 'online' && seenRecently;
    }

    get welcomeExplainerShown() {
        return this.explainersShown?.welcome;
    }

    get hasCompanyLinked() {
        return this.company?.id;
    }

    get hasActiveMembership() {
        return this.membership?.status === 'active';
    }

    get hasExpiredMembership() {
        if(!this.membership?.expiredAt) {
            return false;
        }

        const expiryDate = moment.unix(this.membership?.expiredAt.seconds);
        const today = moment();
        console.log(`${this.fullName} - today: ${today.format('YYYY/MM/DD')}, expiry: ${expiryDate.format('YYYY/MM/DD')} `)
        return today >= expiryDate;
    }

    static pronouneHim(gender?: Gender) {
        switch (gender) {
            case 'male':
                return 'him';
            case 'female':
                return 'her'
            default:
                return 'them';
        }
    }

    static pronouneHe(gender?: Gender, suffix = false) {
        switch (gender) {
            case 'male':
                return 'he' + (suffix ? `'s` : '');
            case 'female':
                return 'she' + (suffix ? `'s` : '');
            default:
                return 'they' + (suffix ? `'re` : '');
        }
    }


    static pronouneHis = (gender?: Gender) => {
        switch (gender) {
            case 'male':
                return 'his';
            case 'female':
                return 'her'
            default:
                return 'their';
        }
    }

    static shortName(firstName?: string, lastName?: string) {
        return `${firstName} ${lastName?.charAt(0).toUpperCase()}.`;
    }

    static fullName(user: UserPublicData | UserModel) {
        return `${user.firstName} ${user.lastName}`;
    }

    static title(role?: string, company?: CompanyInfoModel) {
        if (!company) {
            return role;
        }

        return role + ' @ ' + company?.name;
    }

    toPublicData = (): UserPublicData => {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            photoUrl: this.photoUrl,
            role: this.role,
            bio: this.bio,
            company: this.company,
            location: this.location,
            badges: this.badges,
            sectors: this.interests,
            timezone: this.timezone,
            isManager: this.isManager,
            isStaff: this.isStaff,
            gender: this.gender,
            accomplishments: this.accomplishments,
            followers: this.followers,
            following: this.following,
            createdAt: this.createdAt,
        }
    }

    static mapFromServer(data: any) {
        return new UserModel(data.id,
            data.firstName,
            data.lastName,
            data.email,
            data.photoUrl,
            data.role,
            data.bio,
            data.leadersMember,
            data.onboardingCompleted,
            data.primarySectors,
            data.secondarySectors,
            data.company,
            data.objectives,
            data.suggestedMembers,
            data.kapara,
            data.manager,
            data.settings,
            data.location,
            data.badges,
            data.type,
            data.presence,
            data.timezone,
            data.guest,
            data.guestInvitationId,
            data.explainersShown,
            data.staff,
            data.test,
            data.gender,
            data.followers,
            data.following,
            data.referrerUser,
            data.accomplishments,
            data.createdAt,
            data.stats,
            data.membership,
            data.phoneNumber);
    }

    mapToSever() {
        return JSON.parse(JSON.stringify(this));
    }
}

export type CompanyInfoModel = {
    id?: string,
    logoUrl?: string,
    name?: string,
    shortDescription?: string,
    slug?: string,
}

export type ObjectiveModel = {
    id?: string
}

export type UserStats = {
    eventsRegistered?: number,
    eventsAttended?: number,
    intros?: number,
}

export type Settings = {
    siderCollpased?: boolean
    hideChatCard?: boolean,
}

export type Presence = {
    state?: string,
    lastChanged: any
}

export type Badges = {
    admin?: boolean,
    moderator?: boolean,
    foundingMember?: boolean,
}

export type Membership = {
    expiredAt?: any, 
    status?: 'trial' | 'active',
    plan?: string,
    credits?: Credits,
    totalRevenue: number,
    initialStartDate: any,
    lastPaymentWallHitDate: any,
}

export type Credits = {
    events: number,
    intros: number,
}

export type ActivityItem = {
    type: ActivityType,
    icon: ActivityIcon,
    message: string,
    createdAt: number, 
}


export type ActivityType = 'content' | 'session' | 'profile' | 'event' | 'engagement' | 'payment' 
export type ActivityIcon = 'regular' | 'calendar' | 'info' | 'success' | 'warning' | 'money' | 'view' | 'follow' | 'unfollow' | 'intro';

export type Gender = 'male' | 'female' | 'non-binary';

export type Location = {
    city?: string,
    country?: string,
    isEU?: boolean,
    lastUpdate?: any;
    countryCode?: string,
    region?: string,
    continent?: string,
    latitude?: number,
    longitude?: number,
    flagIcon?: string,
}


export type UserPublicData = {
    id?: string,
    firstName?: string,
    lastName?: string,
    photoUrl?: string,
    role?: string,
    bio?: string,
    company?: CompanyInfoModel,
    location?: Location,
    badges?: Badges,
    sectors?: string[],
    timezone?: string,
    isManager?: boolean,
    isStaff?: boolean,
    gender?: Gender,
    accomplishments?: Accomplishments,
    followers?: string[];
    following?: string[];
    createdAt?: any,
}

export type Explainers = {
    welcome: boolean,
}


export type Accomplishments = {
    attendedToEvent?: boolean
    registeredToEvent?: boolean,
    lastEventDateAttended?: number;
}

export class ConversationModel {
    id?: string;
    participants?: MeetingParticipant[];
    date?: number;
    notes?: string;
    happend?: boolean;
    feedback?: Feedback;

    constructor(id?: string,
        participants?: MeetingParticipant[],
        date?: number,
        notes?: string,
        feedback?: Feedback,
        happend?: boolean) {
        this.id = id;
        this.participants = participants;
        this.date = date;
        this.notes = notes;
        this.feedback = feedback;
        this.happend = happend;
    }

    get isFuture() {
        return moment().unix() < (this.date || 0);
    }

    static mapFromServer(data: any) {
        return new ConversationModel(data.id,
            data.participants,
            data.date,
            data.notes,
            data.feedback,
            data.happend);
    }
}

export type Feedback = {
    score: number,
}


export default UserModel;