import _ from 'lodash';
import { observable, makeObservable, computed, runInAction, action, makeAutoObservable, reaction } from 'mobx';
import * as Sentry from "@sentry/react";
import InvitationModel from '../common/models/InvitationModel';
import UserModel, { ActivityIcon, ActivityItem, ActivityType } from '../common/models/UserModel';
import AnalyticsReporter from '../services/analytics/AnalyticsReporter';
import FirebaseClient, { LoginProvider } from '../services/FirebaseClient'
import UserInfoProvider from '../services/UserInfoProvider';
import RootStore from './RootStore';
import moment from 'moment';
import SettingsStore from './SettingsStore';
import RemoteSettingsProvider from '../services/settings/RemoteSettingsProvider';

export const INVIATION_CAHCE_KEY = 'invitation';

class SessionStore {
    firebase: FirebaseClient;
    settingsStore: SettingsStore;
    authUser?: UserModel;
    sessionFetched = false;
    loggedIn = false;
    invitation?: InvitationModel;
    userListener: () => void = () => { };
    rootStore: RootStore;
    analyticsReporter: AnalyticsReporter;
    userInfoProvider: UserInfoProvider;
    surpressUserListener = false;
    remoteSettingsProvider: RemoteSettingsProvider;

    constructor(rootStore: RootStore,
        firebase: FirebaseClient,
        analyticsReporter: AnalyticsReporter,
        settingsStore: SettingsStore,
        remoteSettingsProvider: RemoteSettingsProvider) {
        makeObservable(this, {
            sessionFetched: observable,
            authUser: observable,
            invitation: observable,
            loggedIn: observable,
            isAuthed: computed,
            logout: action,
            isLeadersMember: computed,
            isGuest: computed,
            profilePhoto: computed,
            fullName: computed,
            onboardingCompleted: computed,
            hasActiveMembership: computed,
        })

        this.firebase = firebase;
        this.rootStore = rootStore;
        this.settingsStore = settingsStore;
        this.analyticsReporter = analyticsReporter;
        this.remoteSettingsProvider = remoteSettingsProvider;
        this.userInfoProvider = new UserInfoProvider();

        this.extractInvitaitonCache();
        this.observeAuth();
    }

    get hasActiveMembership() {
        const membership = this.settingsStore.membership;
        if (!membership?.enabled) {
            return true;
        }

        return this.authUser?.hasActiveMembership;
    }

    get isAuthed() {
        return this.sessionFetched && this.authUser;
    }

    get accessPermitted() {
        return this.isLeadersMember || this.settingsStore.access?.accessLevel === 'public' || this.isGuest || this.isStaff || this.authUser?.test;
    }

    get isLeadersMember() {
        return this.authUser?.leadersMember;
    }

    get isGuest() {
        return this.authUser?.guest;
    }

    get isStaff() {
        return this.authUser?.staff ? true : false;
    }

    get hasInvitation() {
        return this.invitation ? true : false;
    }

    get fullName() {
        return this.authUser?.fullName;
    }

    get profilePhoto() {
        return this.authUser?.photoUrl;
    }

    get onboardingCompleted() {
        return this.authUser?.onboardingCompleted;
    }
    
    get earlyAccess() {
        return this.authUser?.isManager || !this.remoteSettingsProvider.gatesClosed();
    }

    onAuth(callback: (auth?: UserModel) => void) {
        reaction(
            () => this.loggedIn,
            () => callback(this.authUser)
        )
    }

    setAuthUser(authUser?: UserModel) {
        this.authUser = authUser;
        this.sessionFetched = true;
    };

    updateUser = async (change: any) => {
        this.firebase.updateUser(this.authUser!.id!, change);
    }

    changeProfilePicture = async (file: File) => {
        const uploadedImage = await this.firebase.upload(file, 'profile');
        await this.updateUser({ photoUrl: uploadedImage });
        return uploadedImage;
    }

    async loginSSO(provider: LoginProvider) {
        return this.firebase.loginSSO(provider);
    }

    handleEmailLoginIfNeeded = async () => {
        return this.firebase.handleEmailLogin()
    }

    sendEmailLoginLink = async (email: string) => {
        var actionCodeSettings = {
            url: `${window.location.href}`,
            handleCodeInApp: true,
        };

        await this.firebase.sendEmailLoginLink(email, actionCodeSettings)
        window.localStorage.setItem('emailForSignIn', email);

        runInAction(() => {
            this.surpressUserListener = true;
            console.log('Stopped Listening for user changes');
        })

    }

    changeEmail = async (newEmail: string) => {
        await this.firebase.changeEmail(newEmail);
    }

    async getInvitation(id: string) {
        this.removeInvitation(); // remove prev invitation
        const invitation = await this.firebase.fetchInvitationInfo(id);
        if (_.isEmpty(invitation)) {
            throw new Error('Invalid invitation');
        }

        runInAction(() => {
            this.invitation = invitation;
            window.localStorage.setItem(INVIATION_CAHCE_KEY, JSON.stringify(invitation));
        })

        return invitation;
    }

    removeInvitation = () => {
        if (this.invitation && !this.invitation.isGuest) {
            return; // fix bug with member invitations on mobile
        }

        window.localStorage.removeItem(INVIATION_CAHCE_KEY);
        this.invitation = undefined;
    }


    logout = async () => {
        await this.firebase.logout();
        this.userListener && this.userListener();
    }

    isInvitationLink = (urlPath: string) => {
        return _.includes(urlPath, 'invitation');
    }

    submitFeedback = async (freeText: string, reasons: string[]) => {
        await this.firebase.userSubmitFeedback(this.authUser?.fullName, this.authUser?.company?.name, freeText, reasons, this.authUser?.email);
    }

    welcomeExplainerShown = async () => {
        await this.firebase.updateUser(this.authUser?.id!, {
            explainersShown: {
                welcome: true
            }
        });

        this.addActivityItem('Welcome pop-up has been shown', 'content', 'view');
    }

    attendedToEventAccomplished = async () => {
        await this.firebase.updateUser(this.authUser?.id!, {
            accomplishments: {
                attendedToEvent: true,
                lastEventDateAttended: moment().unix()
            }
        })
    }

    addActivityItem = async (message: string, type: ActivityType, icon: ActivityIcon = 'regular') => {
        this.firebase.userActivity(this.authUser?.id!).add({
            type,
            message,
            icon,
            createdAt: moment().unix()
        } as ActivityItem).then(() => { }).catch(error => {
            console.error('Error while adding activity item: ' + error);
        });
    }

    listenForUser = () => {
        runInAction(() => {
            this.surpressUserListener = false;
        })
    }

    observeAuth() {
        this.firebase.onAuthStatusChange(async (user) => {
            if (user) {
                if (this.surpressUserListener) {
                    return;
                }

                const userResult = await this.firebase.user(user.uid).get()
                runInAction(() => {
                    this.setAuthUser(UserModel.mapFromServer({ ...userResult.data(), id: user.uid }));
                    this.loggedIn = true;
                });

                this.setUserChangedListener(user.uid);
                this.firebase.handleUserPresence(user);
                this.analyticsReporter.setUserId(user.uid, this.authUser);
                Sentry.setUser({ email: user.email, id: user.uid });
            } else {
                runInAction(() => {
                    this.setAuthUser(undefined);
                    this.loggedIn = false;
                });
            }
        })
    }

    setUserChangedListener = (id: string) => {
        console.log('Listening to user: ' + id);
        this.userListener = this.firebase.user(id).onSnapshot({ includeMetadataChanges: true }, snap => {
            var source = snap.metadata.hasPendingWrites ? "Local" : "Server";
            if (source === 'Local') return;
            let userData = snap.data();
            const userModel = UserModel.mapFromServer({ ...userData, id });
            if (userModel.membership?.status === 'active'
                && this.authUser?.membership?.status === 'trial') {
                this.rootStore.viewStateStore.setCheckoutCompleted();
            }

            runInAction(() => {
                this.setAuthUser(userModel);
            });

        });
    }

    private extractInvitaitonCache() {
        try {
            const invitationCache = window.localStorage.getItem(INVIATION_CAHCE_KEY);
            if (invitationCache) {
                this.invitation = InvitationModel.mapFromServer(JSON.parse(invitationCache));
            }
        } catch (error) {
            console.error(`Error while getting invitaion cache: ${error}`);
        }
    }

}


export default SessionStore;

