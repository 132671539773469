
import _ from 'lodash';
import { observable, makeObservable, computed, runInAction, action, makeAutoObservable } from 'mobx';
import moment from 'moment';
import AttendeeModel, { AttendeeRole } from '../common/models/AttendeeModel';
import EventModel, { EventFeedback, EventReminder, Question, Speaker, TargetAudience } from '../common/models/EventModel';
import UserModel from '../common/models/UserModel';
import StringUtils from '../common/utils/StringUtils';
import FirebaseClient, { FieldValue } from '../services/FirebaseClient';
import RootStore from './RootStore';
import SessionStore from './SessionStore';
import SettingsStore from './SettingsStore';

export const PASS_EVENTS_THRESHOLD = 8 * 60;
export const MAX_EVENTS_TO_SHOW = 3;
class EventsStore {
    rootStore: RootStore;
    sessionStore: SessionStore;
    settingsStore: SettingsStore;
    firebase: FirebaseClient;
    futureEvents: EventModel[] = [];
    pastEvents: EventModel[] = [];
    eventsListener?: () => void;
    pastEventsListener?: () => void;
    eventsFetched: boolean = false;

    constructor(rootStore: RootStore, sessionStore: SessionStore, firebase: FirebaseClient, settingsStore: SettingsStore) {
        makeObservable(this, {
            futureEvents: observable,
            eventsFetched: observable,
            upcomingEventsStrip: computed,
            futureEventsByDate: computed,
        })

        this.rootStore = rootStore;
        this.sessionStore = sessionStore;
        this.firebase = firebase;
        this.settingsStore = settingsStore;

        this.sessionStore.onAuth((auth) => {
            if (auth) {
                this.addEventsListener();
                this.addPastEventsListener();
            } else {
                this.eventsListener && this.eventsListener();
                this.pastEventsListener && this.pastEventsListener();
            }
        })
    }

    getEvent = async (eventId: string) => {
        return this.firebase.getEventByShortId(eventId);
    }

    get upcomingEventsStrip() {
        return _.take(this.futureEvents, MAX_EVENTS_TO_SHOW);
    }

    get futureEventsByDate() {
        const result = _.groupBy(this.futureEvents, e => moment.unix(e.startDate!).format('DD/MM/YYYY'));
        return result;
    }


    checkIfApprovalNeeded = (event?: EventModel) => {
        const { authUser, invitation } = this.sessionStore;

        if (authUser?.isStaff) {
            return false;
        }

        if (event?.approvalNeeded) {
            if (_.some(event?.approvalNeeded, a => a === 'members')) {
                if (authUser?.leadersMember) {
                    return true;
                }
            }
            if (_.some(event?.approvalNeeded, a => a === 'guests')) {
                if (authUser?.guest) {
                    return invitation?.skipApproval ? false : true;
                }
            }
        }

        return false;
    }

    attendEvent = async (event?: EventModel, attendeeRole: AttendeeRole = 'attendee', ignoreMembership = false) => {
        const { authUser } = this.sessionStore;

        const waitingApproval = this.checkIfApprovalNeeded(event);

        const result = await this.firebase.attendToEvent(event?.id!, {
            id: this.sessionStore.authUser?.id,
            user: this.sessionStore.authUser,
            role: attendeeRole,
            eventId: event?.id,
            waitingApproval: waitingApproval,
        }, true, ignoreMembership) as { registered: boolean, reason?: string }

        if (!result.registered) {
            return result;
        }

        if (!authUser?.accomplishments?.registeredToEvent) {
            this.sessionStore.updateUser({
                accomplishments: {
                    registeredToEvent: true,
                }
            })
        }

        this.sessionStore.updateUser({
            stats: {
                eventsRegistered: FieldValue.increment(1)
            }
        });

        this.sessionStore.addActivityItem(`Registered to event${event?.title ? `: ${event.title}` : ''}`, 'event', 'calendar');
        return result;
    }


    unattendEvent = async (event?: EventModel, ignoreMembership = false) => {
        await this.firebase.unattendToEvent(event?.id!, this.sessionStore.authUser!, ignoreMembership)

        this.sessionStore.addActivityItem(`Canceled registration to event: ${event?.title}`, 'event', 'calendar');
        this.sessionStore.updateUser({
            stats: {
                eventsRegistered: FieldValue.increment(-1)
            }
        })
    }

    addEvent = async (data: EventData) => {
        await this.firebase.addEvent(data)
    }

    editEvent = async (id: string, change: EventData) => {
        await this.firebase.updateEvent(id, change)
    }

    deleteEvent = async (eventId: string) => {
        await this.firebase.deleteEvent(eventId);
    }

    startEvent = async (eventId: string) => {
        await this.firebase.startEvent(eventId);
    }

    endEvent = async (eventId: string) => {
        await this.firebase.endEvent(eventId);
    }

    createGuestInvitation = async (event: EventModel, guestRole: AttendeeRole, skipApproval: boolean) => {
        const eventInfo = event.toPublicData();
        const invitationId = await this.firebase.generateGuestInvitation(this.sessionStore.authUser!, eventInfo, guestRole, skipApproval);
        return { id: invitationId, url: `${window.location.protocol}//${window.location.host}/guest-invitation/${invitationId}` };
    }

    updateGuestInvitation = async (id: string, change: any) => {
        await this.firebase.invitation(id).update({
            ...change,
        });
    }

    collectFeedback = async (eventId: string) => {
        await this.firebase.collectEventFeedback(eventId);
    }

    addQuestion = async (eventId: string, question: Question) => {
        await this.firebase.addQuestionToEvent(eventId, question);
    }

    addAttendee = async (eventId: string, userModel: UserModel) => {
        return this.firebase.attendToEvent(eventId, {
            id: userModel.id!,
            user: userModel,
            role: 'attendee',
        }, false,
            true);
    }

    approveAttendee = async (eventId: string, userModel: UserModel) => {
        return this.firebase.attendToEvent(eventId, {
            id: userModel.id!,
            user: userModel,
            waitingApproval: false,
        }, true, true);
    }

    denyAttendee = async (eventId: string, userModel: UserModel) => {
        return this.firebase.unattendToEvent(eventId, userModel, false, true);
    }

    addFeedbackRating = async (event: EventModel, score: number) => {
        const { authUser } = this.sessionStore;
        await this.firebase.eventFeedback(event.id!, authUser?.id!).set({
            score: score,
            by: authUser?.toPublicData(),
        }, { merge: true })

        this.updateAttendee(event.id!, {
            feedbackSubmitted: true,
        });

        this.sessionStore.addActivityItem(`Feedback submitted to event: ${event.title}`, 'event');
    }

    addFeedbackNotes = async (eventId: string, notes: string) => {
        const { authUser } = this.sessionStore;
        await this.firebase.eventFeedback(eventId, authUser?.id!).set({
            notes: notes,
        }, { merge: true })
    }

    updateAttendee = async (eventId: string, change: any) => {
        const { authUser } = this.sessionStore;
        await this.firebase.event(eventId)
            .collection('attendees')
            .doc(authUser!.id!).update({
                ...change,
            });
    }

    removeAttendee = async (eventId: string, attendee: AttendeeModel) => {
        return this.firebase.unattendToEvent(eventId, attendee.user!, true)
    }

    getAttendees = async (eventId: string) => {
        const attendees = await this.firebase.eventAttendees(eventId).get();
        return attendees.docs.map(a => {
            return { id: a.id, ...a.data() } as AttendeeModel;
        })
    }

    reportAttended = async (event: EventModel) => {
        const { authUser } = this.sessionStore;
        if (!authUser) {
            return;
        }

        const currentAttendee = await this.firebase.fetchAttendee(event.id!, authUser.id!);

        if (!currentAttendee?.attended) {
            this.sessionStore.addActivityItem(`Attended event: ${event?.title}`, 'event', 'calendar');
        }

        this.firebase.eventAttendee(event?.id!, authUser.id!).update({
            attended: true,
        });
    }

    getFeedbacks = async (eventId: string) => {
        const feedbacks = await this.firebase.eventFeedbacks(eventId).get();
        return feedbacks.docs.map(a => {
            return { id: a.id, ...a.data() } as EventFeedback;
        })
    }

    updateStats = async (eventId: string, stats: any) => {
        this.firebase.event(eventId).set({
            stats: {
                ...stats,
            }
        }, { merge: true })
    }

    private filterTargetedEvents = (events: EventModel[]) => {
        const currentUser = this.sessionStore.authUser!;

        if (currentUser.isStaff) {
            return events;
        }

        const targetedEvents: EventModel[] = [];
        events.forEach(event => {
            if (!event.targets || event.targets.length === 0) { // no targeting -> for everyone
                if (event.isHidden) {
                    return;
                }

                targetedEvents.push(event);
                return;
            }

            event.targets.forEach((target) => {
                if (target.type === 'members') {
                    if (target.members.find(member => member.id === currentUser.id)) {
                        targetedEvents.push(event);
                    }
                }
            })

        });

        return targetedEvents;
    }

    addEventsListener = () => {
        const now = moment().unix() - PASS_EVENTS_THRESHOLD; // keep today's events 
        this.eventsListener = this.firebase.events()
            .where('endDate', '>=', now)
            .orderBy('endDate', 'asc')
            .onSnapshot(snap => {
                const events: EventModel[] = [];
                snap.docs.map(doc => {
                    events.push(EventModel.mapFromServer({ id: doc.id, ...doc.data() }));
                })

                runInAction(() => {
                    this.futureEvents = this.filterTargetedEvents(events);;
                    this.eventsFetched = true;
                });
            });
    }

    addPastEventsListener = () => {
        const now = moment().unix() - PASS_EVENTS_THRESHOLD; // keep today's events 
        this.pastEventsListener = this.firebase.events()
            .where('endDate', '<=', now)
            .orderBy('endDate', 'desc')
            .onSnapshot(snap => {
                const events: EventModel[] = [];
                snap.docs.map(doc => {
                    events.push(EventModel.mapFromServer({ id: doc.id, ...doc.data() }));
                })

                runInAction(() => {
                    this.pastEvents = this.filterTargetedEvents(events);;
                });
            });
    }
}

export type EventData = {
    title?: string,
    description?: string,
    startDate?: number,
    endDate?: number,
    timezone?: string,
    type?: 'online' | 'physical',
    location?: string,
    speakers?: Speaker[],
    eventPlatform?: 'in-house' | 'external',
    theme?: 'forum' | 'panelist' | 'other',
    themeColor?: string,
    themeTitle?: string,
    isQuestionsEnabled?: boolean,
    isActive?: boolean,
    isEnded?: boolean,
    isHidden?: boolean,
    webinarId?: string,
    spatialLink?: string,
    externalLink?: string,
    themeBackground?: string,
    targets?: TargetAudience[],
    seatLimit?: number,
    autoStart?: boolean,
    recordUrl?: string,
    approvalNeeded?: string[],
    reminder?: EventReminder;
}


export default EventsStore;


